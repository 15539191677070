<template>
  <div>
    <v-app>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>You have existing pages from your current active theme.</v-card-title>
            <v-card-subtitle>Please confirm how would you like to proceed</v-card-subtitle>

            <v-card-text>
              <v-divider></v-divider>
              <v-list two-line>
                <v-list-item-group active-class="green--text">
                  <v-list-item three-line @click.prevent="option2">
                    <v-list-item-content>
                      <v-list-item-title>New Theme Activation with Demo Pages</v-list-item-title>
                      <v-list-item-subtitle>This option will import all sample pages from the new theme including new header, footer and color schemes. Your existing pages may not be accurate in design but you can edit the new imported pages and start editing with your existing content</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item three-line @click.prevent="option3">
                    <v-list-item-content>
                      <v-list-item-title>Activate Theme Only. No Demo Pages Available</v-list-item-title>
                      <v-list-item-subtitle>This option will not import any sample pages from the new theme. Only the header, footer and color schemes will be updated. The resulting website may not look as the original theme demo and will need additional work to organise website pages.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined class="mx-auto" max-width="300">
            <progressive-img :src="template.thumbnail_image" contain></progressive-img>
            <v-card-title>{{ template.title }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>
<script>
import Websitetemplate from "@/services/Websites/WebsiteTemplateService";
const WebsiteTemplateService = new Websitetemplate();
import WebsiteMarketplaceProduct from "@/services/Websites/WebsiteMarketPlaceProductService";
const WebsiteMarketPlaceProductService = new WebsiteMarketplaceProduct();
import Website from "@/services/Websites/WebsiteService";
const WebsiteService = new Website();
export default {
  name: "website-template",
  data() {
    return {
      templates: [],
      template: null,
      selected: [1],
      website: {
        id: null,
        title: null,
        user_id: null,
        template_id: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: null,
        linked_organisation_id: null
      },
      pages: [""],
      importPage: {
        pages: null
      }
    };
  },
  methods: {
    option1() {
      this.$root
        .$confirm("Confirm", "Are You Sure ? ", {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {

            this.$snotify.success("Settings  Updated");
          }
        });
    },
    option2() {
      this.$root
        .$confirm("Confirm", "Are You Sure ? ", {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {
            this.website.template_id = this.templateName;
            WebsiteService.update(this.domainName, this.website).then(
              res => {
                WebsiteTemplateService.deleteAll(this.siteUrl)
                  .then(res => {
                    WebsiteTemplateService.getPageByTemplate(this.templateName)
                      .then(res => {
                        this.pages = res.data;
                        this.import();

                        // this.settings.setup_steps = this.setup_steps;
                      })
                      .catch(err => {});
                  })
                  .catch(err => {

                  });
              }
            );
          }
        });
    },
    option3() {
      this.$root
        .$confirm("Confirm", "Are You Sure ? ", {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {
            alert("option3");
            this.website.template_id = this.templateName;
            WebsiteService.update(this.domainName, this.website).then(
              res => {
                this.$snotify.success("Template  Updated");
              }
            );
          }
        });
    },
    import() {
      this.importPage.pages = this.pages;
      WebsiteTemplateService.importPage(
        this.domainName,
        this.website.template_id,
        this.importPage
      )
        .then(res => {


          this.$snotify.success("Template  Updated");
        })
        .catch(err => {});
    },
    getMarketPlaceTemplate() {
      WebsiteMarketPlaceProductService.paginate()
        .then(res => {
          this.templates = res.data.data;
        })
        .catch(err => {});
    },
    initWebsite() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
    getTemplate() {
      WebsiteTemplateService.get(this.templateName).then(res => {

        this.template = res.data.product;
      });
    }
  },
  components: {},
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Web Dashboard", route: "/websites" }
    // ]);
    this.getMarketPlaceTemplate();
    this.initWebsite();
    this.getTemplate();
  },
  computed: {
    siteUrl() {
      return this.$route.params.domainname;
    },
    templateName() {
      return this.$route.params.templatename;
    },
    domainName(){
      if(this.website.is_custom_domain){
        return this.website.domain_name;
      }
      else{
           return this.website.sub_domain_name;
      }
    }
  }
};
</script>
